<template>
  <div class="activity-top wrapper flex" v-if="data">
    <img :src="data.activity_image" alt="" class="img" />
    <div class="activity-info-right">
      <div class="title">{{ data.activity_title }}</div>
      <div class="apply-info">
        <div class="info-list">
          <img src="~@/assets/calendar.png" alt="" />
          报名截止： {{ data.activity_apply_end_time }}
        </div>
        <div class="info-list">
          <img src="~@/assets/person.png" alt="" />
          报名人数： <span>{{ data.activity_apply_num }}</span
          >人已报名
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, defineComponent, reactive, toRefs } from "@vue/runtime-core";
export default defineComponent({
  name: "activityTop",
  components: {},
  props: {
    data: {
      type: Object,
      default: null
    }
  },
  setup(props) {
    return {};
  }
});
</script>
<style lang="less" scoped>
.activity-top {
  margin-top: 40px;
  .img {
    width: 181px;
    height: 131px;
    background-color: #1276cb;
    border-radius: 10px;
    margin-right: 35px;
  }
  .activity-info-right {
    height: 131px;
    padding: 5px 0;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .title {
      color: #212531;
      font-size: 20px;
    }
    .info-list {
      color: #212531;
      font-size: 16px;
      margin-top:10px;
      > img {
        margin-right: 10px;
      }
      span {
        color: #1276cb;
        font-weight: bold;
      }
    }
  }
}
</style>
