<template>
  <div class="activity-apply wrapper page-wrap">
    <a-spin :spinning="loading">
      <activity-top :data="activity"></activity-top>
      <div class="line"></div>
      <a-form
        ref="formRef"
        :model="form"
        :label-col="labelCol"
        @finish="handleSubmit"
        class="apply-form"
      >
        <a-form-item
          name="apply_name"
          label="姓名"
          :rules="{ required: true, message: '请输入姓名' }"
        >
          <a-input
            size="large"
            v-model:value="form.apply_name"
            placeholder="请输入姓名"
          >
          </a-input>
        </a-form-item>
        <a-form-item
          name="apply_phone"
          label="电话"
          :rules="{ required: true, message: '请输入电话' }"
        >
          <a-input
            size="large"
            v-model:value="form.apply_phone"
            placeholder="请输入电话"
          >
          </a-input>
        </a-form-item>
        <a-form-item name="apply_idcard" label="身份证号">
          <a-input
            size="large"
            v-model:value="form.apply_idcard"
            placeholder="请输入身份证号码"
          >
          </a-input>
        </a-form-item>
        <a-form-item class="apply-btn-wrap">
          <a-button
            block
            size="large"
            type="primary"
            html-type="submit"
            class="btn"
            :loading="loadingBtn"
            @click="handleSubmit"
          >
            提交
          </a-button>
        </a-form-item>
      </a-form>
    </a-spin>
  </div>
</template>

<script>
import {
  computed,
  defineComponent,
  reactive,
  toRefs,
  watch,
  ref
} from "@vue/runtime-core";
import { getActivityData, insertActivityApplyData } from "@/api/main.js";
import { message } from "ant-design-vue";
import url from "@/utils/url.js";
import { getUserId, getUserData } from "@/utils/storeData.js";
import { useRoute } from "vue-router";
import { encode, decode } from "js-base64";
import ActivityTop from "@/components/activityTop.vue";
export default defineComponent({
  name: "activityApply",
  components: {
    ActivityTop
  },
  setup() {
    const route = useRoute();
    const formRef = ref();
    const pageData = reactive({
      activity: null,
      activityId: null,
      user_id: computed(() => getUserId()),
      loading: true,
      loadingBtn: false,
      form: {
        apply_name: null,
        apply_phone: null,
        apply_idcard: null
      }
    });
    const getActivityDataFun = _activityId => {
      getActivityData({ activity_id: _activityId })
        .then(res => {
          if (res.code == 200) {
            pageData.activity = res.data.activity;
          } else {
            message.error(res.msg);
          }
        })
        .catch(res => {
          console.log(res);
          message.error("网络有误，请稍后再试");
        })
        .finally(() => {
          pageData.loading = false;
        });
    };
    const goToApply = () => {
      url.navigateTo("/activity/apply", {
        t: encode(`${pageData.activity.activity_id}`)
      });
    };
    const handleSubmit = e => {
      e.preventDefault();
      formRef.value
        .validate()
        .then(res => {
          pageData.loadingBtn = true;
          pageData.loading = true;
          insertActivityApplyData({
            ...res,
            user_id: pageData.user_id,
            activity_id: pageData.activityId
          })
            .then(res => {
              if (res.code == 200) {
                message.info("提交成功");
                url.navigateTo("/activity/applyDetail", {
                  t: encode(`${res.data.apply_id},${pageData.activityId}`)
                });
              } else {
                message.error(res.msg);
              }
            })
            .catch(res => {
              console.log(res);
              message.error("网络有误，请稍后再试");
            })
            .finally(() => {
              pageData.loading = false;
              pageData.loadingBtn = false;
            });
        })
        .catch(res => {
          console.log(res);
        })
        .finally(() => {
          pageData.loadingBtn = false;
          pageData.loadingForm = false;
        });
    };
    watch(
      () => route.query,
      val => {
        if (route.path == "/activity/apply") {
          let query = decode(val?.t).split(",");
          if (query[0]) {
            pageData.loading = true;
            pageData.activityId = query[0];
            getActivityDataFun(query[0]);
          } else {
            pageData.loading = false;
            pageData.activity = null;
            message.error("暂无活动信息");
          }
        }
      },
      {
        immediate: true,
        deep: true
      }
    );
    return {
      ...toRefs(pageData),
      goToApply,
      formRef,
      handleSubmit,
      labelCol: { style: { width: "100px" } }
    };
  }
});
</script>
<style lang="less">
.activity-apply {
  .line {
    height: 10px;
    background-color: #f3f5f8;
    margin-top: 30px;
  }
  .apply-form {
    margin: 40px auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    .ant-row {
      width: 800px;
    }
  }
  .ant-form-item {
    align-items: center;
  }
  .apply-btn-wrap {
    .ant-form-item-control-input-content {
      display: flex;
      justify-content: center;
    }
  }
}
</style>
